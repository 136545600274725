import React, { Component } from "react";
import { Container, Grid } from "semantic-ui-react";
import BloqHandler from "./BloqHandler";

class BloqRow extends Component {
  render() {
    const data = this.props.data;
    var fluid = false;
    if (data.width === "fluid") {
      fluid = true;
    }

    return (
      <Container
        fluid={fluid}
        style={{
          padding: `${data.padding}`,
          backgroundColor: `${data.backgroundColor}`,
          backgroundImage: `url(${data.backgroundImage})`,
          backgroundPosition: `${data.backgroundPosition}`,
          backgroundRepeat: "no-repeat",
          backgroundSize: `${data.backgroundSize}`,
        }}
        key={data.bloqId}
      >
        {data.nested ? (
          <Grid
            centered
            verticalAlign={data.columnAlign}
            className={
              data.reverseMobile === "1" ? "mobile vertically reversed" : null
            }
          >
            {data.nested.map((bloq) => (
              <BloqHandler data={bloq} key={bloq.bloqId} />
            ))}
          </Grid>
        ) : (
          <Container>
            <p>This wow has no children</p>
          </Container>
        )}
      </Container>
    );
  }
}

export default BloqRow;
