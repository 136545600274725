import React, { Component, Fragment } from "react";

import {
  Button,
  Header,
  Icon,
  Image,
  Label,
  Segment,
  Table,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

class BloqLoopLitters extends Component {
  render() {
    const litters = this.props.data.items;

    if (litters[0]) {
      if (litters[0].no_results) {
        return (
          <Segment inverted color="yellow">
            <p>{litters[0].no_results}</p>
          </Segment>
        );
      } else {
        return (
          <Table celled textAlign="center" className="littersTable">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Mom</Table.HeaderCell>
                <Table.HeaderCell>Dad</Table.HeaderCell>
                <Table.HeaderCell>Due Date</Table.HeaderCell>
                <Table.HeaderCell>Notes</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {litters.map((litter) => (
                <Table.Row key={litter.id}>
                  <Table.Cell>
                    <Link to={`/our-hedgehogs/${litter.mom[0].handle}`}>
                      <Image
                        src={litter.mom[0].image}
                        alt={`Photo of ${litter.mom[0].name}`}
                        size="medium"
                        className="photoTrim"
                      />
                    </Link>
                    <Header>{litter.mom[0].name}</Header>
                    <Button
                      as={Link}
                      to={`/our-hedgehogs/${litter.mom[0].handle}`}
                      primary
                    >
                      Details
                      <Icon name="arrow right" />
                    </Button>
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={`/our-hedgehogs/${litter.dad[0].handle}`}>
                      <Image
                        src={litter.dad[0].image}
                        alt={`Photo of ${litter.dad[0].name}`}
                        size="medium"
                        className="photoTrim"
                      />
                    </Link>
                    <Header>{litter.dad[0].name}</Header>
                    <Button
                      as={Link}
                      to={`/our-hedgehogs/${litter.dad[0].handle}`}
                      primary
                    >
                      Details
                      <Icon name="arrow right" />
                    </Button>
                  </Table.Cell>
                  <Table.Cell>
                    <span className="dateLine">{litter.approxDate}</span>
                    {/* {litter.exactDate !== "null" ? (
                      <Label as="span" basic color="red">
                        <Icon name="lock" />
                        {litter.exactDate}
                      </Label>
                    ) : null} */}
                  </Table.Cell>
                  <Table.Cell>
                    <dl className="litterExpected">
                      <dt>Expected Colors:</dt>
                      {litter.colors[0] ? (
                        <Label.Group as="dd" size="large">
                          {litter.colors.map((color) => (
                            <Label className={`color-${color.slug}`}>
                              {color.name}
                            </Label>
                          ))}
                        </Label.Group>
                      ) : (
                        <dd>
                          <Label>TBD</Label>
                        </dd>
                      )}
                      <br />
                      <dt>Expected Patterns:</dt>
                      {litter.patterns[0] ? (
                        <Label.Group as="dd" size="large">
                          {litter.patterns.map((pattern) => (
                            <Label>{pattern.name}</Label>
                          ))}
                        </Label.Group>
                      ) : (
                        <dd>TBD</dd>
                      )}
                    </dl>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        );
      }
    } else {
      return <p>loading</p>;
    }
  }
}

export default BloqLoopLitters;
