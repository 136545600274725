import React, { Component } from "react";
import { Container, Grid, Segment, Tab } from "semantic-ui-react";
import BloqHandler from "./BloqHandler";

class BloqTabWrapper extends Component {
  render() {
    const data = this.props.data;
    const tabs = data.nested;
    var panes = [];
    console.log(data.nested);

    for (let tab of tabs) {
      panes.push({
        menuItem: tab.menuItem,
        render: () => (
          <Tab.Pane>
            {tab.nested ? (
              <Grid centered verticalAlign="middle">
                {tab.nested.map((bloq) => (
                  <BloqHandler data={bloq} key={bloq.bloqId} />
                ))}
              </Grid>
            ) : (
              <Segment basic>
                <p>This section has no children</p>
              </Segment>
            )}
          </Tab.Pane>
        ),
      });
    }

    return (
      <Tab
        as={Container}
        style={{ paddingLeft: 0, paddingRight: 0 }}
        panes={panes}
      />
    );
  }
}

export default BloqTabWrapper;
