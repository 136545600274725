import React, { Component } from "react";
import HelmetMeta from "./HelmetMeta";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import { Placeholder } from "semantic-ui-react";

class PostView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageMeta: { headerBgColor: "gradient-warm", title: "Blog" },
      breadcrumbs: [{ content: "Home" }, { content: "Blog" }],
      post: {},
    };
  }

  handlePathChange = () => {
    var crumbs = this.state.breadcrumbs;
    this.props.onPathChange(crumbs);
  };

  componentDidMount() {
    const slug = this.props.match.params.handle;
    axios
      .get(`https://admin.suncoasthedgehogs.com/eeData/blogView/${slug}`)
      .then((blog) => {
        this.setState({
          blog: blog,
          pageMeta: blog.data[0].pageMeta[0],
          breadcrumbs: blog.data[0].breadcrumbs,
          post: blog.data[0].post[0],
        });
        this.handlePathChange();
      });
  }

  render() {
    const post = this.state.post;

    if (this.state.post.title) {
      return (
        <div>
          <HelmetMeta meta={post.postMeta[0]} />
          <h1>{this.state.post.title}</h1>
          <div>{ReactHtmlParser(this.state.post.content)}</div>
        </div>
      );
    } else {
      return (
        <Placeholder fluid>
          <Placeholder.Header>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      );
    }
  }
}

export default PostView;
