import React, { Component, Fragment } from "react";
import axios from "axios";
import PageMeta from "./PageMeta";
//import Loading from "./Loader";
import ColorChart from "./ColorChart";
import Slider from "react-slick";
//import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Responsive,
} from "semantic-ui-react";

class ColorChartPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageMeta: {},
      breadcrumbs: {},
      bloqs: {},
    };
  }

  componentDidMount() {
    const handle = this.props.match.params.handle;
    axios
      .get(
        `https://admin.suncoasthedgehogs.com/eeData/pageContent/shopping-cart`
      )
      .then((page) => {
        this.setState({
          pageMeta: page.data.pageMeta[0],
          breadcrumbs: page.data.breadcrumbs,
          bloqs: page.data.blocks,
        });
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.handle !== this.props.match.params.handle) {
      this.setState({
        pageMeta: {},
        breadcrumbs: [{}],
        bloqs: {},
      });
      axios
        .get(
          `https://admin.suncoasthedgehogs.com/eeData/pageContent/shopping-cart`
        )
        .then((page) => {
          this.setState({
            pageMeta: page.data.pageMeta[0],
            breadcrumbs: page.data.breadcrumbs,
            bloqs: page.data.blocks,
          });
        });
    }
  }

  render() {
    const meta = this.state.pageMeta;
    const crumbs = this.state.breadcrumbs;

    return (
      <Fragment>
        <PageMeta path="color-chart" meta={meta} crumbs={crumbs} />
        <Container>
          <Responsive as={Grid} celled className="colorChart" minWidth={768}>
            <ColorChart />
          </Responsive>

          <Responsive as={Grid} celled className="colorChart" maxWidth={767}>
            <ColorChart slider={true} />
          </Responsive>
        </Container>
      </Fragment>
    );
  }
}

export default ColorChartPage;
