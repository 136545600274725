import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { Header, Icon } from "semantic-ui-react";

class BloqHeader extends Component {
  render() {
    const data = this.props.data;
    var linked = false;

    if (data.url !== "") {
      linked = true;
    }

    return (
      <Header
        as={data.element === "link" ? "a" : data.element}
        href={data.element === "link" ? data.url : ""}
        size={data.size}
        className={`${data.font === "display" ? "brush" : ""} ${
          data.caps ? "upper" : ""
        }`}
        color={data.color}
        style={{
          backgroundColor: { ...data.backgroundColor },
          backgroundImage: `url(${data.backgroundImage})`,
          backgroundPosition: "center bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {data.subText && data.swap ? (
          <Header.Subheader>{ReactHtmlParser(data.subText)}</Header.Subheader>
        ) : (
          ""
        )}
        {data.icon ? <Icon name={data.icon} className={data.iconColor} /> : ""}
        <Header.Content>
          {ReactHtmlParser(data.text)}
          {data.subText && !data.swap ? (
            <Header.Subheader>{ReactHtmlParser(data.subText)}</Header.Subheader>
          ) : (
            ""
          )}
        </Header.Content>
      </Header>
    );
  }
}

export default BloqHeader;
