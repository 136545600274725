import React, { Component, Fragment } from "react";
import { Segment } from "semantic-ui-react";
import BloqHandler from "./BloqHandler";
import ReactHtmlParser from "react-html-parser";

class BloqAnnouncement extends Component {
  render() {
    const data = this.props.data;

    return (
      <div style={{ marginBottom: "1em" }}>
        {data.nested.map((announcement) => (
          <Fragment>
            {announcement.nested[0] ? (
              <div>
                {announcement.nested.map((bloq) => (
                  <BloqHandler data={bloq} key={bloq.bloqId} />
                ))}
              </div>
            ) : (
              <Segment inverted color="yellow">
                {ReactHtmlParser(announcement.quickContent)}
              </Segment>
            )}
          </Fragment>
        ))}
      </div>
    );
  }
}

export default BloqAnnouncement;
