import React, { Component } from "react";
import { Button, Grid, Header, Segment } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";

export default class WaitlistNag extends Component {
  render() {
    const fallback = this.props.fallback;
    return (
      <Segment inverted color="yellow" padded="very">
        <Grid textAlign="center">
          <Grid.Column>
            <Header
              as="h2"
              href="/waiting-list"
              size="large"
              color="black"
              className="brush upper"
            >
              <Header.Content>
                <Header.Subheader>
                  {ReactHtmlParser(fallback)}
                  <br />
                  <br />
                </Header.Subheader>
                Want First Dibs on our Next Litter?
              </Header.Content>
            </Header>
            <Button as={Link} color="red" size="large" to="/waiting-list">
              Join Our Waiting List!
            </Button>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}
