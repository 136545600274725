import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Checkbox,
  Grid,
  Header,
  Icon,
  Modal,
  Segment,
} from "semantic-ui-react";

class ConfirmCartPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmAge: false,
      confirmLocation: false,
      confirmPolicy: false,
      confirmShipping: false,
      cartConfirmed: false,
    };
  }

  toggleCheckbox = (confirmed) => {
    if (confirmed === "confirmAge") {
      this.setState((prevState) => ({ confirmAge: !prevState.confirmAge }));
    } else if (confirmed === "confirmLocation") {
      this.setState((prevState) => ({
        confirmLocation: !prevState.confirmLocation,
      }));
    } else if (confirmed === "confirmPolicy") {
      this.setState((prevState) => ({
        confirmPolicy: !prevState.confirmPolicy,
      }));
    } else if (confirmed === "confirmShipping") {
      this.setState((prevState) => ({
        confirmShipping: !prevState.confirmShipping,
      }));
    }
  };

  render() {
    var confirmed = false;
    if (
      this.state.confirmAge &&
      this.state.confirmLocation &&
      this.state.confirmPolicy &&
      this.state.confirmShipping
    ) {
      confirmed = true;
    } else {
      confirmed = false;
    }
    const { confirmAge } = this.state.confirmAge;
    const { confirmLocation } = this.state.confirmLocation;
    const { confirmPolicy } = this.state.confirmPolicy;
    const { confirmShipping } = this.state.confirmShipping;
    const { open, checkoutUrl, confirmClose } = this.props;

    return (
      <Modal size="tiny" open={open} onClose={confirmClose} closeIcon>
        <Modal.Header as={Header} size="large" color="red">
          IMPORTANT!
        </Modal.Header>
        <Fragment>
          <Modal.Content>
            <Grid>
              <Grid.Column width={16}>
                <Checkbox
                  label={
                    <label>
                      I certify that I am at least 18 years of age or have the
                      explicit permission of a parent/guardian to purchase a
                      hedgehog.
                    </label>
                  }
                  onChange={() => this.toggleCheckbox("confirmAge")}
                />
              </Grid.Column>
              <Grid.Column width={16}>
                <Checkbox
                  label={
                    <label>
                      I certify that I do not live in a state or municipality
                      where hedgehogs are illegal, nor am I purchasing this
                      hedgehog on behalf of someone who does.{" "}
                      <em>
                        (Places where hedgehogs are illegal include but are not
                        limited to: California, Georgia, Hawaii, Pennsylvania,
                        and the 5 Boroughs of New York City)
                      </em>
                    </label>
                  }
                  onChange={() => this.toggleCheckbox("confirmLocation")}
                />
              </Grid.Column>
              <Grid.Column width={16}>
                <Checkbox
                  label={
                    <label>
                      I certify that I understand I will have to either pick up
                      my hedgehog in person (at our Jacksonville, FL location)
                      or pay to have my hedgehog shipped via an airline
                      (approximately $450).
                    </label>
                  }
                  onChange={() => this.toggleCheckbox("confirmShipping")}
                />
              </Grid.Column>
              <Grid.Column width={16}>
                <Checkbox
                  label={
                    <label>
                      I certify that I have read the{" "}
                      <Link to="/about/policies">policies</Link> and understand
                      that <strong>NO REFUNDS</strong> will be given for
                      hedgehog purchases or deposits for{" "}
                      <strong>ANY REASON.</strong>
                    </label>
                  }
                  onChange={() => this.toggleCheckbox("confirmPolicy")}
                />
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Grid>
              <Grid.Column textAlign="center">
                <Button
                  disabled={!confirmed}
                  as="a"
                  href={checkoutUrl}
                  icon="cart"
                  labelPosition="right"
                  size="big"
                  content="Proceed to Checkout"
                />
              </Grid.Column>
            </Grid>
          </Modal.Actions>
        </Fragment>
      </Modal>
    );
  }
}

export default ConfirmCartPopup;
