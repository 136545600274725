import React, { Component } from "react";
import {
  Button,
  Container,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Responsive,
  Transition,
} from "semantic-ui-react";
import { NavLink, Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";

import logo from "../images/sch-logo-color.png";

export default class Masthead extends Component {
  state = { visible: false };

  toggleVisibility = () =>
    this.setState((prevState) => ({ visible: !prevState.visible }));

  handleNavItemClick = () => this.setState({ visible: false });

  render() {
    const { visible } = this.state;

    return (
      <header id="sch-masthead">
        <Grid padded>
          <Responsive minWidth={768} as={Grid.Row} color="black">
            <Grid.Column>
              <Container>
                <Grid>
                  <Grid.Row verticalAlign="middle">
                    <Grid.Column tablet={4} computer={3}>
                      <Header inverted as={Link} to="/about/shipping">
                        <Icon
                          name="plane"
                          className="fa-gradient gradient-cool angled"
                        />
                        <Header.Content as="h3">
                          We Ship
                          <Header.Subheader>Learn More</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column tablet={4} computer={7}></Grid.Column>
                    <Grid.Column tablet={4} computer={3}>
                      <Header inverted as={Link} to="/about/contact">
                        <Icon
                          name="phone"
                          className="fa-gradient gradient-warm angled"
                        />
                        <Header.Content as="h3">
                          Phone
                          <Header.Subheader>Learn More</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column tablet={4} computer={3}>
                      <Header inverted as={Link} to="/about/contact">
                        <Icon
                          name="map marker alternate"
                          className="fa-gradient gradient-cool angled"
                        />
                        <Header.Content as="h3">
                          Location
                          <Header.Subheader>Learn More</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    {/* <Grid.Column width={1} textAlign="right">
                      <Header
                        inverted
                        as={Link}
                        to="/about"
                        className="icon-only"
                      >
                        <Icon
                          name="calendar alternate outline"
                          className="fa-gradient gradient-warm angled"
                        />
                        <Header.Content as="h3" className="sr-only">
                          Schedule Pickup
                          <Header.Subheader>Learn More</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column> */}
                  </Grid.Row>
                </Grid>
              </Container>
            </Grid.Column>
          </Responsive>

          <Responsive maxWidth={768} as={Grid.Row} color="black">
            <Grid.Column>
              <Container>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={3}>
                      <Link to="/about/shipping">
                        <Header size="large" className="icon-only">
                          <Icon
                            name="plane"
                            size="large"
                            className="fa-gradient gradient-cool angled"
                          />
                          <span className="sr-only">We Ship</span>
                        </Header>
                      </Link>
                    </Grid.Column>
                    <Grid.Column width={10} textAlign="center">
                      <Link to="/about/contact">
                        <Header size="large" inverted>
                          <Icon
                            size="large"
                            name="map marker alternate"
                            className="fa-gradient gradient-cool angled"
                          />
                          Jax, FL
                        </Header>
                      </Link>
                    </Grid.Column>
                    <Grid.Column width={3} textAlign="right">
                      <Link to="/about/contact">
                        <Header size="large" className="icon-only">
                          <Icon
                            size="large"
                            name="phone"
                            className="fa-gradient gradient-cool angled"
                          />
                          <span className="sr-only">Phone Number</span>
                        </Header>
                      </Link>
                    </Grid.Column>
                    {/* <Grid.Column width={3} textAlign="right">
                      <Link to="/about">
                        <Header size="large" className="icon-only">
                          <Icon
                            size="large"
                            name="calendar alternate outline"
                            className="fa-gradient gradient-warm angled"
                          />
                          <span className="sr-only">Schedule Pickup</span>
                        </Header>
                      </Link>
                    </Grid.Column> */}
                  </Grid.Row>
                </Grid>
              </Container>
            </Grid.Column>
          </Responsive>
        </Grid>

        <Container>
          <Responsive
            as={Grid}
            maxWidth="1200"
            verticalAlign="middle"
            padded="vertically"
          >
            <Grid.Row>
              <Responsive
                as={Grid.Column}
                minWidth={768}
                maxWidth={1200}
                width={5}
              />
              <Responsive
                as={Grid.Column}
                maxWidth={1200}
                mobile={10}
                tablet={6}
                computer={6}
              >
                <Link to="">
                  <Image src={logo} alt="Sun Coast Hedgehogs Logo" />
                </Link>
              </Responsive>
              <Responsive
                as={Grid.Column}
                maxWidth={768}
                width={6}
                textAlign="right"
              >
                <Button onClick={this.toggleVisibility}>Menu</Button>
              </Responsive>
            </Grid.Row>
          </Responsive>

          <Transition visible={visible} animation="slide down" duration={300}>
            <div>
              <MobileMenu
                toggleCart={this.props.toggleCart}
                cartQuantity={this.props.cartQuantity}
                handleNavItemClick={this.handleNavItemClick}
              />
            </div>
            {/* <p>This is a test</p> */}
          </Transition>

          <Menu
            as={Responsive}
            minWidth={768}
            stackable
            borderless
            size="large"
          >
            <Responsive minWidth={1200}>
              <Menu.Item as={Link} to="" name="home" className="navLogo">
                <Image src={logo} alt="Sun Coast Hedgehogs Logo" />
              </Menu.Item>
            </Responsive>
            <Menu.Menu position="right">
              <Menu.Item as={NavLink} exact to="/about">
                About
              </Menu.Item>
              <Dropdown
                item
                simple
                text="Available Hedgehogs"
                as={Link}
                to="/hedgehogs-for-sale"
              >
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <i className="dropdown icon" />
                    <span className="text">Babies</span>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        as={NavLink}
                        exact
                        to="/hedgehogs-for-sale/"
                        name="babies-for-sale"
                      >
                        For Sale
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={NavLink}
                        exact
                        to="/hedgehogs-for-sale/category/on-hold"
                        name="on-hold"
                      >
                        On Hold
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={NavLink}
                        exact
                        to="/hedgehogs-for-sale/category/sold"
                        name="adults"
                      >
                        Sold
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    exact
                    to="/hedgehogs-for-sale/category/adult-rehomes"
                    name="adults"
                  >
                    Adults
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    exact
                    to="/upcoming-litters"
                    name="litters"
                  >
                    Upcoming Litters
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    exact
                    to="/about/pricing-list"
                    name="pricing"
                  >
                    Pricing List
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    exact
                    to="/waiting-list"
                    name="waiting-list"
                  >
                    Waiting List
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Menu.Item as={NavLink} to="/our-hedgehogs">
                Our Hedgehogs
              </Menu.Item>
              <Menu.Item as={NavLink} to="/supplies">
                Supplies
              </Menu.Item>
              <Menu.Item as={NavLink} to="/blog-coming-soon" name="blog">
                Blog
              </Menu.Item>
              <Menu.Item as={NavLink} exact to="/about/faq">
                FAQ
              </Menu.Item>
              {/* <Menu.Item as={NavLink} to="/about">
                Contact
              </Menu.Item> */}
              <Menu.Item as={NavLink} to="/cart">
                Cart {this.props.cartQuantity}
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Container>
      </header>
    );
  }
}
