import React, { Component } from "react";
import axios from "axios";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import HedgieView from "./HedgieView";
import Loader from "./Loader";

class Hedgie extends Component {
  constructor() {
    super();
    this.state = {
      eeData: {},
      pageMeta: {},
      breadcrumbs: [],
    };
  }

  handlePathChange = () => {
    var crumbs = this.state.breadcrumbs;
    var meta = this.state.pageMeta;
    this.props.onPathChange(crumbs, meta);
  };

  componentDidMount() {
    axios
      .get(
        `https://admin.suncoasthedgehogs.com/eeData/hedgie/${this.props.match.params.handle}`
      )
      .then((eeData) => {
        this.setState({
          eeData: eeData.data[0].hedgie[0],
          pageMeta: eeData.data[0].pageMeta[0],
          breadcrumbs: eeData.data[0].breadcrumbs,
        });
        this.handlePathChange();
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.handle !== this.props.match.params.handle) {
      this.setState({
        eeData: {},
        pageMeta: {},
        breadcrumbs: [],
      });
      axios
        .get(
          `https://admin.suncoasthedgehogs.com/eeData/hedgie/${nextProps.match.params.handle}`
        )
        .then((eeData) => {
          this.setState({
            eeData: eeData.data[0].hedgie[0],
            pageMeta: eeData.data[0].pageMeta[0],
            breadcrumbs: eeData.data[0].breadcrumbs,
          });
          this.handlePathChange();
        });
    }
  }

  createProductQuery(handle) {
    return gql`
      query query {
        shop {
          name
          productByHandle(handle: "${handle}") {
            title
            handle
            id
            description
            tags
            productType
            totalInventory
            options {
              id
              name
              values
            }
            metafields(first: 10) {
              edges {
                node {
                  id
                  key
                  value
                }
              }
            }
            variants(first: 10) {
              edges {
                node {
                  id
                  title
                  availableForSale
                  price
                  priceV2 {
                    amount
                  }
                  selectedOptions {
                    name
                    value
                  }
                  image {
                    altText
                    thumbnailImg: transformedSrc(
                      maxWidth: 100
                      maxHeight: 100
                      crop: CENTER
                    )
                    productImg: transformedSrc(
                      maxWidth: 800
                      maxHeight: 800
                      crop: CENTER
                    )
                  }
                }
              }
            }
            images(first: 50) {
              edges {
                node {
                  altText
                  thumbnailImg: transformedSrc(
                    maxWidth: 100
                    maxHeight: 100
                    crop: CENTER
                  )
                  productImg: transformedSrc(
                    maxWidth: 800
                    maxHeight: 800
                    crop: CENTER
                  )
                }
              }
            }
          }
        }
      }
    `;
  }

  render() {
    if (this.state.eeData.name) {
      return (
        <Query query={this.createProductQuery(this.props.match.params.handle)}>
          {({ loading, err, data }) => {
            if (loading) {
              return <Loader />;
            } else if (err) {
              return <p>Error</p>;
            } else {
              if (data.shop.productByHandle) {
                const product = data.shop.productByHandle;
                return (
                  <HedgieView
                    eeData={this.state.eeData}
                    product={product}
                    addVariant={this.props.addVariant}
                  />
                );
              } else {
                return (
                  <HedgieView
                    eeData={this.state.eeData}
                    product=""
                    addVariant={this.props.addVariant}
                  />
                );
              }
            }
          }}
        </Query>
      );
    } else {
      return <Loader />;
    }
  }
}

export default Hedgie;
