import React, { Component, Fragment } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import BloqHandler from "./BloqHandler";
import ReactHtmlParser from "react-html-parser";

class BloqAccordion extends Component {
  state = { activeIndex: 0 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const data = this.props.data;

    return (
      <div className="accordion-container">
        <Accordion>
          {data.nested.map((panel) => (
            <Fragment>
              <Accordion.Title
                active={activeIndex === panel.accId}
                index={panel.accId}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                {ReactHtmlParser(panel.title)}
              </Accordion.Title>
              {panel.nested ? (
                <Accordion.Content active={activeIndex === panel.accId}>
                  {panel.nested.map((bloq) => (
                    <BloqHandler data={bloq} key={bloq.bloqId} />
                  ))}
                </Accordion.Content>
              ) : (
                <Accordion.Content active={activeIndex === panel.accId}>
                  {ReactHtmlParser(panel.content)}
                </Accordion.Content>
              )}
            </Fragment>
          ))}
        </Accordion>
      </div>
    );
  }
}

export default BloqAccordion;
