import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { Button, Card, Image } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";

class HedgieCard extends Component {
  render() {
    const hedgie = this.props.hedgie;
    const herd = this.props.herd;
    var genderCode = "?";
    if (hedgie.gender === "Male") {
      genderCode = "M";
    } else if (hedgie.gender === "Female") {
      genderCode = "F";
    }
    return (
      <Card>
        <div className={`genderTag ${hedgie.gender}`}>
          <svg
            id="genderRibbon"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 64"
          >
            <polygon points="24.48 47.83 1 62.22 1 -1 49 -1 49 62.25 24.48 47.83" />
            <path d="M48,0V60.5L25.5,47.28l-1-.61-1,.62L2,60.43V0H48m2-2H0V64L24.49,49,50,64V-2Z" />
          </svg>
          <span>{genderCode}</span>
        </div>
        <Link
          to={`/${herd ? "our-hedgehogs" : "hedgehogs-for-sale"}/${
            hedgie.slug
          }`}
        >
          <Image src={hedgie.images[0].url} alt={hedgie.name} />
        </Link>
        <Card.Content>
          {/* <Card.Header>{hedgie.name}</Card.Header> */}
          <dl className="hedgieMeta">
            <div className="dl__item">
              <dt>Name</dt>
              <dd>{ReactHtmlParser(hedgie.name)}</dd>
            </div>
            <div className="dl__item sr-only">
              <dt>Gender</dt>
              <dd>{hedgie.gender ? hedgie.gender : "TBD"}</dd>
            </div>
            <div className="dl__item">
              <dt>Born</dt>
              <dd>{hedgie.dob ? hedgie.dob : "TBD"}</dd>
            </div>
            {herd ? (
              <div className="dl__item">
                <dt>Color</dt>
                <dd>{hedgie.colors ? hedgie.colors : "TBD"}</dd>
              </div>
            ) : (
              <Fragment>
                <div className="dl__item">
                  <dt>Likely Color</dt>
                  <dd>{hedgie.colors ? hedgie.colors : "TBD"}</dd>
                </div>
                <Fragment>
                  {hedgie.status === "Picked Up" ? (
                    <div className="dl__item">
                      <dt>Went Home With</dt>
                      <dd>{hedgie.hold ? hedgie.hold : "N/A"}</dd>
                    </div>
                  ) : (
                    <div className="dl__item">
                      <dt>Ready to Go Home</dt>
                      <dd>{hedgie.home ? hedgie.home : "TBD"}</dd>
                    </div>
                  )}
                </Fragment>
              </Fragment>
            )}
          </dl>
          {herd ? null : (
            <Fragment>
              {hedgie.status === "On Hold" ? (
                <span className="holdLine">
                  On Hold {hedgie.hold ? `for: ${hedgie.hold}` : null}
                </span>
              ) : null}
              {hedgie.status === "Sold" ? (
                <span className="soldLine">
                  Sold {hedgie.hold ? `to: ${hedgie.hold}` : null}
                </span>
              ) : null}
            </Fragment>
          )}
        </Card.Content>
        <Card.Content extra>
          {hedgie.status === "open" && !herd ? (
            <span className="price">${hedgie.price}</span>
          ) : null}
          <Button
            primary
            size="mini"
            as={Link}
            to={`/${herd ? "our-hedgehogs" : "hedgehogs-for-sale"}/${
              hedgie.slug
            }`}
          >
            {hedgie.status === "open" && !herd ? "More Info / Buy Now" : null}
            {hedgie.status === "On Hold" ? "Pay Balance" : null}
            {herd ||
            hedgie.status === "Sold - Ready for Pickup" ||
            hedgie.status === "Taken Home"
              ? "More Photos & Info"
              : null}
          </Button>
        </Card.Content>
      </Card>
    );
  }
}
export default HedgieCard;
