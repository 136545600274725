import React, { Component } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { Card, Segment, Grid, Header, Button } from "semantic-ui-react";

import ProductCard from "../components/ProductCard";
import HedgieCardShopify from "../components/HedgieCardShopify";
import WaitlistNag from "../components/WaitlistNag";

class ProductsGrid extends Component {
  createCollectionQuery(collection) {
    return gql`
      query query {
        shop {
          name
          collectionByHandle(handle: "${collection}") {
            title
            products(first: ${this.props.limit}) {
              edges {
                node {
                  title
                  id
                  handle
                  productType
                  vendor
                  images(first: 10) {
                    edges {
                      node {
                        id
                        src
                      }
                    }
                  }
                  metafields(first: 10) {
                    edges {
                      node {
                        id
                        key
                        value
                      }
                    }
                  }
                  tags
                  priceRange {
                    maxVariantPrice {
                      amount
                    }
                    minVariantPrice {
                      amount
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
  }

  render() {
    const fallback = this.props.fallback;
    return (
      <Query query={this.createCollectionQuery(this.props.handle)}>
        {({ loading, err, data }) => {
          if (loading) {
            return <p>Loading</p>;
          } else if (err) {
            return <p>Error</p>;
          } else {
            if (data.shop.collectionByHandle) {
              const collection = data.shop.collectionByHandle;
              const products = collection.products.edges;
              if (
                this.props.handle === "supplies" ||
                this.props.handle === "shampoo-cage-cleaner" ||
                this.props.handle === "food-supplements" ||
                this.props.handle === "bundles-value-sets" ||
                this.props.handle === "cage-supplies"
              ) {
                return (
                  <Card.Group itemsPerRow={4} doubling stackable>
                    {products.map((product) => (
                      <ProductCard
                        product={product}
                        stars={this.props.stars}
                        key={product.node.id}
                      />
                    ))}
                  </Card.Group>
                );
              } else {
                if (products.length >= 1) {
                  return (
                    <Card.Group
                      itemsPerRow={4}
                      doubling
                      stackable
                      className="hedgieTeaser"
                    >
                      {products.map((hedgie) => (
                        <HedgieCardShopify
                          hedgie={hedgie.node}
                          stars={false}
                          key={hedgie.node.id}
                        />
                      ))}
                    </Card.Group>
                  );
                } else {
                  if (
                    this.props.handle == "babies-for-sale" ||
                    this.props.handle == "for-sale"
                  ) {
                    return <WaitlistNag fallback={fallback} />;
                  } else {
                    return (
                      <Segment inverted color="yellow">
                        <p>{ReactHtmlParser(fallback)}</p>
                      </Segment>
                    );
                  }
                }
              }
            } else {
              return <p>Return!</p>;
            }
          }
        }}
      </Query>
    );
  }
}

export default ProductsGrid;
