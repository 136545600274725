import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import BloqHandler from "./BloqHandler";

class BloqColumn extends Component {
  render() {
    const data = this.props.data;
    return (
      <Grid.Column
        key={data.blockId}
        mobile={data.mobile}
        tablet={data.tablet}
        computer={data.desktop}
        textAlign={data.align}
        style={{ padding: data.padding }}
      >
        {data.nested
          ? data.nested.map((child) => (
              <BloqHandler data={child} key={child.bloqId} />
            ))
          : "This column has no children"}
      </Grid.Column>
    );
  }
}

export default BloqColumn;
