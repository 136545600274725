import React from "react";
import { Icon } from "semantic-ui-react";

const Loading = () => (
  <div className="loader">
    <Icon
      loading
      name="sun"
      size="huge"
      className="fa-gradient gradient-warm angled"
    />
  </div>
);

export default Loading;
