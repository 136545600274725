import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Button, Card, Image } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";

class HedgieCardShopify extends Component {
  render() {
    const hedgie = this.props.hedgie;
    const tags = this.props.hedgie.tags;
    var gender = "";
    var color = "";
    var pattern = "";
    var marking = "";
    var dob = "";
    var home = "";
    var hold = "";
    var status = "";

    tags.map(function (tag) {
      if (tag.startsWith("GEN:")) {
        gender = tag.replace("GEN:", "");
      }
      if (tag.startsWith("COLOR:")) {
        color = tag.replace("COLOR:", "");
      }
      if (tag.startsWith("PATTERN:")) {
        pattern = tag.replace("PATTERN:", "");
      }
      if (tag.startsWith("MARKING:")) {
        marking = tag.replace("MARKING:", "");
      }
      if (tag.startsWith("DOB:")) {
        dob = tag.replace("DOB:", "");
      }
      if (tag.startsWith("HOME:")) {
        home = tag.replace("HOME:", "");
      }
      if (tag.startsWith("HOLD:")) {
        hold = tag.replace("HOLD:", "");
      }
      if (tag === "open") {
        status = "For Sale";
      }
      if (tag === "Waitlist Only") {
        status = "Waitlist Only";
      }
      if (tag === "On Hold") {
        status = "On Hold";
      }
      if (tag === "Sold") {
        status = "Sold";
      }
      if (tag === "Taken Home") {
        status = "Picked Up";
      }
    });

    var genderCode = "?";
    if (gender === "Male") {
      genderCode = "M";
    } else if (gender === "Female") {
      genderCode = "F";
    }

    return (
      <Card>
        <div className={`genderTag ${gender}`}>
          <svg
            id="genderRibbon"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 64"
          >
            <polygon points="24.48 47.83 1 62.22 1 -1 49 -1 49 62.25 24.48 47.83" />
            <path d="M48,0V60.5L25.5,47.28l-1-.61-1,.62L2,60.43V0H48m2-2H0V64L24.49,49,50,64V-2Z" />
          </svg>
          <span>{genderCode}</span>
        </div>
        <Link to={`/hedgehogs-for-sale/${hedgie.handle}`}>
          <Image src={hedgie.images.edges[0].node.src} alt={hedgie.title} />
        </Link>
        <Card.Content>
          {/* <Card.Header>{hedgie.name}</Card.Header> */}
          <dl className="hedgieMeta">
            <div className="dl__item">
              <dt>Name</dt>
              <dd>{ReactHtmlParser(hedgie.title)}</dd>
            </div>
            <div className="dl__item sr-only">
              <dt>Gender</dt>
              <dd>{gender ? gender : "TBD"}</dd>
            </div>
            <div className="dl__item">
              <dt>Born</dt>
              <dd>{dob ? dob : "N/A"}</dd>
            </div>
            <div className="dl__item">
              <dt>Likely Color</dt>
              <dd>{color ? color : "TBD"}</dd>
            </div>
            {status === "Picked Up" ? (
              <div className="dl__item">
                <dt>Went Home With</dt>
                <dd>{hold ? hold : "N/A"}</dd>
              </div>
            ) : (
              <div className="dl__item">
                <dt>Ready to Go Home</dt>
                <dd>{home ? home : "TBD"}</dd>
              </div>
            )}
          </dl>
          {status === "On Hold" ? (
            <span className="holdLine">
              On Hold {hold ? `for: ${hold}` : null}
            </span>
          ) : null}
          {status === "Waitlist Only" ? (
            <span className="holdLine">
              On Hold{hold ? ` for: ${hold}` : `: waitlist only`}
            </span>
          ) : null}
          {status === "Sold" ? (
            <span className="soldLine">Sold {hold ? `to: ${hold}` : null}</span>
          ) : null}
        </Card.Content>
        <Card.Content extra>
          {status === "For Sale" || status === "Waitlist Only" ? (
            <span className="price">
              ${hedgie.priceRange.maxVariantPrice.amount * 1}
            </span>
          ) : null}
          <Button
            primary
            size="mini"
            as={Link}
            to={`/hedgehogs-for-sale/${hedgie.handle}`}
          >
            {status === "For Sale" || status === "Waitlist Only"
              ? "More Info / Buy Now"
              : null}
            {status === "On Hold" ? "Pay Balance" : null}
            {status === "Sold" || status === "Picked Up"
              ? "More Photos & Info"
              : null}
          </Button>
        </Card.Content>
      </Card>
    );
  }
}
export default HedgieCardShopify;
