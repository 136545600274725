import React, { Component, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PageMeta from "../components/PageMeta";
import PageContent from "../components/PageContent";
import HedgieList from "../components/HedgieList";
import Hedgie from "../components/Hedgie";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

class HedgieLayout extends Component {
  constructor(props) {
    const pathSegment = props.location.pathname;
    super(props);
    if (pathSegment.includes("our-hedg")) {
      this.state = {
        pageMeta: { headerBgColor: "gradient-cool", title: "Our Hedgehogs" },
        breadcrumbs: [{ content: "Home" }, { content: "Our Hedgehogs" }],
      };
    } else {
      this.state = {
        pageMeta: {
          headerBgColor: "gradient-warm",
          title: "Available Hedgehogs",
        },
        breadcrumbs: [{ content: "Home" }, { content: "Available Hedgehogs" }],
      };
    }
  }

  handleCrumbs = (breadcrumbValue, metaValue) => {
    this.setState({ breadcrumbs: breadcrumbValue });
    this.setState({ pageMeta: metaValue });
  };

  render() {
    const meta = this.state.pageMeta;
    const crumbs = this.state.breadcrumbs;
    const { match, addVariant } = this.props;
    const pathSegment = this.props.location.pathname;

    return (
      <Fragment>
        <ScrollToTopOnMount />
        <PageMeta path={pathSegment} meta={meta} crumbs={crumbs} />
        <Switch>
          <Route
            exact
            path={match.path}
            render={(props) => (
              <PageContent
                {...props}
                onPathChange={this.handleCrumbs}
                handle={pathSegment}
              />
            )}
          />
          <Route
            path={`${match.path}/category/:handle`}
            exact
            render={(props) => (
              <PageContent
                {...props}
                category
                onPathChange={this.handleCrumbs}
                handle={pathSegment}
              />
            )}
          />
          <Redirect
            from="/hedgehogs-for-sale/detail/:handle"
            to={`${match.path}/:handle`}
          />
          <Route
            path={`${match.path}/:handle`}
            render={(props) => (
              <Hedgie
                {...props}
                onPathChange={this.handleCrumbs}
                addVariant={addVariant}
              />
            )}
          />
        </Switch>
      </Fragment>
    );
  }
}

export default HedgieLayout;
