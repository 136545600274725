import React, { Component } from "react";

class BloqLoopBlog extends Component {
  render() {
    const bloq = this.props.data;
    return <p>This is a blog loop.</p>;
  }
}

export default BloqLoopBlog;
