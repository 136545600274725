import React, { Component } from "react";
import { Helmet } from "react-helmet";

class HelmetMeta extends Component {
  render() {
    const meta = this.props.meta;
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{meta.titleSEO}</title>
        <meta property="og:title" content={meta.titleOG} />
        <meta name="description" content={meta.descSEO} />
        <meta property="og:description" content={meta.descOG} />
        <meta
          property="og:image"
          content="http://www.suncoasthedgehogs.com/images/logo-suncoast-hedgehogs.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Sun Coast Hedgehogs" />
        <meta
          property="og:url"
          content={`https://suncoasthedgehogs.com${this.props.path}`}
        />
        <link
          rel="canonical"
          href={`https://suncoasthedgehogs.com${this.props.path}`}
        />
      </Helmet>
    );
  }
}

export default HelmetMeta;
