import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { Container, Grid, Header, Icon, Image } from "semantic-ui-react";

class BloqImageGrid extends Component {
  render() {
    const data = this.props.data;
    var classes = "";
    var circular = 0;
    var stacking = 0;
    var photo = 0;
    if (data.iconColor.indexOf("gradient") >= 0) {
      if (data.style === "circular") {
        classes = data.iconColor;
        circular = 1;
      } else {
        classes = "fa-gradient " + data.iconColor;
      }
    }
    if (data.itemsPerRow === 4 || data.itemsPerRow === "default") {
      stacking = 1;
    }
    if (data.style === "photo") {
      photo = 1;
    }

    return (
      <Grid
        className="imageGrid"
        columns={data.itemsPerRow === "default" ? 4 : data.itemsPerRow}
        doubling
        stackable={stacking}
      >
        {data.grid.map((item) => (
          <Grid.Column key={`${data.bloqId}-${item.imgId}`}>
            <Container textAlign="center">
              {item.icon ? (
                <Icon
                  name={item.icon}
                  color={data.iconColor}
                  className={classes}
                  size="huge"
                  circular={circular}
                  inverted={circular}
                />
              ) : (
                <Image
                  src={item.file}
                  className={`centered ${photo ? "photoTrim" : null}`}
                  circular={circular}
                />
              )}
            </Container>
            <Header
              color={data.labelColor}
              className={data.caps ? "upper" : null}
            >
              {item.label}
            </Header>
            <p>{ReactHtmlParser(item.caption)}</p>
          </Grid.Column>
        ))}
      </Grid>
    );
  }
}

export default BloqImageGrid;
