import React, { Component } from "react";
import {
  Button,
  Container,
  Divider,
  Grid,
  Icon,
  Image,
  Responsive,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import moment from "moment";
import Instagram from "./Instagram";

import logo from "../images/sch-logo-white.png";
import usda from "../images/icon_usda.png";

import pp_paypal from "../images/pp_paypal.png";
import pp_visa from "../images/pp_visa.png";
import pp_mastercard from "../images/pp_mastercard.png";
import pp_amex from "../images/pp_amex.png";

export default class Footer extends Component {
  state = {};

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    var copyYear = moment().format("YYYY");
    return (
      <div>
        {/* <Instagram showHeader="true" /> */}
        <footer className="gradient-warm">
          <Grid padded>
            <Grid.Row>
              <Grid.Column>
                <Container className="footer-content">
                  <Grid divided centered padded verticalAlign="middle">
                    <Grid.Row>
                      <Grid.Column mobile={14} tablet={5} computer={5}>
                        <Image src={logo} alt="Sun Coast Hedgehogs logo" />
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={11} computer={11}>
                        <p>
                          This website was last updated 01/27/21. We keep the
                          available babies up to date at all times, we update
                          this date when we make a major change to the site.
                        </p>
                        <p>
                          Copyright &copy; 2015-{copyYear} Sun Coast Hedgehogs
                          &bull; Website by{" "}
                          <a
                            href="https://gobrightmarketing.com/"
                            target="_blank"
                          >
                            Go Bright Marketing
                          </a>{" "}
                          &bull;{" "}
                          <Link to="/privacy-policy">Privacy Policy</Link>
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Responsive as={Divider} minWidth={768} />
                  <Grid centered padded verticalAlign="middle">
                    <Grid.Row>
                      <Grid.Column
                        mobile={16}
                        tablet={9}
                        computer={7}
                        textAlign="center"
                      >
                        <Image.Group size="vtiny">
                          <Image src={pp_paypal} alt="PayPal accepted" />
                          <Image src={pp_visa} alt="VISA accepted" />
                          <Image
                            src={pp_mastercard}
                            alt="Mastercard accepted"
                          />
                          <Image
                            src={pp_amex}
                            alt="American Express accepted"
                          />
                        </Image.Group>
                      </Grid.Column>
                      <Grid.Column
                        mobile={7}
                        tablet={4}
                        computer={3}
                        textAlign="center"
                      >
                        <Button
                          as="a"
                          href="https://www.facebook.com/suncoasthedgies"
                          target="_blank"
                          icon
                          compact
                          secondary
                          size="huge"
                        >
                          <Icon name="facebook f" />
                        </Button>
                        <Button
                          as="a"
                          href="https://www.instagram.com/suncoasthedgies/"
                          target="_blank"
                          icon
                          compact
                          secondary
                          size="huge"
                        >
                          <Icon name="instagram" />
                        </Button>
                      </Grid.Column>
                      <Grid.Column
                        mobile={5}
                        tablet={2}
                        computer={2}
                        textAlign="center"
                      >
                        <Image
                          centered
                          src={usda}
                          size="vtiny"
                          alt="USDA Licensed Breeders"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </footer>
      </div>
    );
  }
}
