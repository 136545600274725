import React, { Component } from "react";
import axios from "axios";
import Slider from "react-slick";
import { Container } from "semantic-ui-react";
import BloqHandler from "../bloqs/BloqHandler";

class HeroSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: [],
    };
  }

  componentDidMount() {
    axios
      .get(`https://admin.suncoasthedgehogs.com/eeData/slides`)
      .then((slides) => {
        this.setState({
          slides: slides.data,
        });
      });
  }

  render() {
    var settings = {
      autoplay: true,
      autoplaySpeed: 4000,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <Container fluid textAlign="center" style={{ marginBottom: "2em" }}>
        {this.state.slides ? (
          <Slider {...settings}>
            {this.state.slides.map((slide) => (
              <div
                className="slideContainer"
                key={slide.slideWrapper[0].slideId}
              >
                <div
                  className={
                    slide.slideWrapper[0].textColor === "inverted"
                      ? "slideWrapper inverted"
                      : "slideWrapper"
                  }
                  style={{
                    backgroundColor: `${slide.slideWrapper[0].backgroundColor}`,
                    backgroundImage: `url(${slide.slideWrapper[0].backgroundImage})`,
                    backgroundPosition: `${slide.slideWrapper[0].backgroundPosition}`,
                    backgroundSize: "cover",
                    padding: "3em 0",
                  }}
                >
                  <Container>
                    {slide.blocks.map((bloq) => (
                      <BloqHandler data={bloq} key={bloq.bloqId} />
                    ))}
                  </Container>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <p>Slider loading...</p>
        )}
      </Container>
    );
  }
}

export default HeroSlider;
