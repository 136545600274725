import React, { Component } from "react";
import { Grid, Header, Image, Segment } from "semantic-ui-react";

class Instagram extends Component {
  render() {
    return (
      <div style={{ padding: "3em 0" }}>
        {this.props.showHeader ? (
          <Header
            as="h2"
            size="huge"
            color="red"
            textAlign="center"
            className="brush"
          >
            Follow Us on Instagram!
          </Header>
        ) : null}
        <Segment basic>
          <Grid>
            <Grid.Row columns={6}>
              <Grid.Column>
                <Image src="https://placehold.it/400x400" />
              </Grid.Column>
              <Grid.Column>
                <Image src="https://placehold.it/400x400" />
              </Grid.Column>
              <Grid.Column>
                <Image src="https://placehold.it/400x400" />
              </Grid.Column>
              <Grid.Column>
                <Image src="https://placehold.it/400x400" />
              </Grid.Column>
              <Grid.Column>
                <Image src="https://placehold.it/400x400" />
              </Grid.Column>
              <Grid.Column>
                <Image src="https://placehold.it/400x400" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }
}

export default Instagram;
