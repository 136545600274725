import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Image, Card } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";

class ProductCard extends Component {
  render() {
    const product = this.props.product;
    const priceCurrency = (
      Math.round(product.node.priceRange.minVariantPrice.amount * 100) / 100
    ).toFixed(2);

    return (
      <Card width={4} className="productCard">
        <Link to={`/supplies/${product.node.handle}`}>
          <Image src={product.node.images.edges[0].node.src} alt="test" />
          {console.log(product.node.metafields)}
        </Link>
        <Card.Content>
          <Card.Header as="h4" className="productTitle">
            {product.node.title}
          </Card.Header>
          <Card.Meta className="productMeta">
            {product.node.productType}
          </Card.Meta>
        </Card.Content>
        <Card.Content extra>
          {this.props.stars ? (
            <Card.Meta className="productRating">
              {/* <Icon name="star" color="yellow" size="large" />
              <Icon name="star" color="yellow" size="large" />
              <Icon name="star" color="yellow" size="large" />
              <Icon name="star" color="yellow" size="large" />
              <Icon name="star half" color="yellow" size="large" /> */}
            </Card.Meta>
          ) : null}
          <Card.Header className="productPrice">${priceCurrency}</Card.Header>
          <Button
            as={Link}
            to={`/supplies/${product.node.handle}`}
            primary
            size="small"
          >
            More Info / Buy Now
          </Button>
        </Card.Content>
      </Card>
    );
  }
}
export default ProductCard;
