import React, { Component, Fragment } from "react";

class BloqReviews extends Component {
  componentDidMount() {
    let script = document.createElement("script");
    script.setAttribute("id", "stampedIoEmbed");
    script.onload = function () {
      let newScript = document.createElement("script");
      let inlineScript = document.createTextNode(
        'StampedFn.init({apiKey : "pubkey-5KHwdfGsI363067Gw0k7rEc1kK2C3m",storeUrl : "hedgehogsandfriends.myshopify.com"});'
      );
      newScript.setAttribute("id", "stampedIoWidget");
      newScript.appendChild(inlineScript);
      document.getElementsByTagName("head")[0].appendChild(newScript);
    };
    script.src = "https://cdn1.stamped.io/files/widget.min.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  componentWillUnmount() {
    let stampedIoEmbed = document.getElementById("stampedIoEmbed");
    let stampedIoWidget = document.getElementById("stampedIoWidget");
    if (stampedIoEmbed && stampedIoWidget) {
      stampedIoEmbed.remove();
      stampedIoWidget.remove();
    }
  }

  render() {
    const data = this.props.data;

    return (
      <Fragment>
        <div
          id="stamped-reviews-widget"
          data-widget-type="carousel"
          data-auto-play={data.autoplay}
          data-auto-play-speed={data.autoplaySpeed}
          // data-style-color-star={data.starColor}
          // data-style-color-link={data.linkColor}
          data-review-ids={data.reviewIds}
          data-product-ids={data.productIds}
          data-product-category={data.productTypes}
          data-product-brand={data.vendors}
          data-tags={data.tags}
          data-min-rating="4"
          data-fill-empty="true"
        ></div>
      </Fragment>
    );
  }
}

export default BloqReviews;
