import React, { Component, Fragment } from "react";
import axios from "axios";
import Loading from "./Loader";
import BloqHandler from "../bloqs/BloqHandler";

class PageContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageMeta: {},
      breadcrumbs: [{ content: "Home" }, { content: "" }],
      bloqs: {},
    };
  }

  handlePathChange = () => {
    var meta = this.state.pageMeta;
    var crumbs = this.state.breadcrumbs;
    this.props.onPathChange(crumbs, meta);
  };

  componentDidMount() {
    const handle = this.props.handle;
    const pagesURI = "" + handle;
    axios.get(`https://admin.suncoasthedgehogs.com${pagesURI}`).then((page) => {
      this.setState({
        pageMeta: page.data.pageMeta[0],
        breadcrumbs: page.data.breadcrumbs,
        bloqs: page.data.blocks,
      });
      this.handlePathChange();
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.handle !== this.props.handle) {
      const pagesURI = "" + nextProps.handle;
      this.setState({
        pageMeta: {},
        breadcrumbs: [{ content: "Home" }, { content: "" }],
        bloqs: {},
      });
      axios
        .get(`https://admin.suncoasthedgehogs.com${pagesURI}`)
        .then((page) => {
          this.setState({
            pageMeta: page.data.pageMeta[0],
            breadcrumbs: page.data.breadcrumbs,
            bloqs: page.data.blocks,
          });
          this.handlePathChange();
        });
    }
  }

  render() {
    const meta = this.state.pageMeta;
    const bloqs = this.state.bloqs;
    if (meta.title) {
      return (
        <Fragment>
          {bloqs.map((bloq) => (
            <BloqHandler data={bloq} key={bloq.bloqId} />
          ))}
        </Fragment>
      );
    } else {
      return <Loading />;
    }
  }
}

export default PageContent;
